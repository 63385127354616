import io from 'socket.io-client'

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'ws://15.152.246.85:9992'
// const URL = 'ws://localhost:9992'
const URL = 'wss://demo1.xn--9g4bv6ai7g8slcyd.com'

// eslint-disable-next-line no-useless-escape
const clientHost = window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')

export const socket = io(URL, {
  path: '/socket.io',
  transports: ['websocket'],
  timestampParam: 'time',
  timestampRequests: true,
  cors: {
    origin: '*',
  },
  reconnection: true,
  reconnectionAttempts: 10, // 10번 재연결 시도 후 실패 처리
  reconnectionDelay: 1000, // 첫 재연결 시 1초 대기
  reconnectionDelayMax: 5000, // 최대 5초 대기
  randomizationFactor: 0.5,
  autoConnect: true, // 자동 연결 활성화
})

/**
 *
 * @param {Socket} soc
 * @param {SocketIOEventName} event
 * @param {Any} data
 * @returns
 */
export const emitPromise = (soc, event, data) => {
  return new Promise((resolve, reject) => {
    soc.emit(event, data, response => {
      if (response.error) {
        reject(response.error)
      } else {
        resolve(response)
      }
    })
  })
}
