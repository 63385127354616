import { commonReg2 } from '@/utils/validate/commonValidate'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { FaArrowRight } from 'react-icons/fa'
import { FaArrowRightArrowLeft } from 'react-icons/fa6'
import { GiMoneyStack } from 'react-icons/gi'
import { HiOutlineRefresh } from 'react-icons/hi'
import { IoWalletOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchGameUrl, searchLiveVendor } from '../../api/game/gameApi'
import CustomAlert from '../../containers/CustomAlert'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { ButtonH40 } from '../../shared/components/GoldButton'
import { ContentRowSmall, ContentRowWrap, ContentWrap } from '../../shared/components/MobileMoneyCustomElement'
import { MobileTopWrap } from '../../shared/components/MoneyCustomElement'
import { HermesMainText, colorMainText, colorMoneyInfo, colorMoneySvg, colorWalletSvg } from '../../utils/palette'
import MobileCasinoGameViewPopup from './MobileCasinoGameViewPopup'

const MobileLiveCasino = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [liveCasinoVendors, setLiveCasinoVendors] = useState([])

  const { holdingMoney, totalGameMoney } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
    }
  })

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  useEffect(() => {
    window.scrollTo(10, 0)

    setLoading(true)
    searchLiveVendor()
      .then(res => {
        setLiveCasinoVendors(res.data.vendors)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const [gameLoading, setGameLoading] = useState(false)

  const [casinoGameViewPopupShow, setCasinoGameViewPopupShow] = useState(false)
  const onCloseCasinoGameViewPopupShowHandler = () => {
    setCasinoGameViewPopupShow(false)
  }

  const [casinoGameUrl, setCasinoGameUrl] = useState('')

  const notGamePopupVendors = ['BOTA', 'ASIA GAMING LIVE', 'SUPERSPADE']

  const gameStartHandler = game => {
    if (gameLoading) return

    if (game.vendorIsInspection) return

    setGameLoading(true)

    searchGameUrl({
      agencyName: game.agencyName,
      vendorType: game.vendorType,
      gameKey: game.vendorKey,
    })
      .then(res => {
        const { gameUrl } = res.data

        if (!gameUrl) {
          // 없어진 게임일때 빈문자열로 내려옴
          onChangeAlertPopupMessageHandler('이용이 불가능합니다. 관리자에게 문의하세요.')
          return
        }

        window.open(
          `${gameUrl}`,
          '',
          `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
        )
        // const idx = notGamePopupVendors.findIndex(item => item === game.vendorName)

        // if (idx === -1) {
        //   setCasinoGameUrl(gameUrl)
        //   setCasinoGameViewPopupShow(true)
        // } else {
        //   window.open(
        //     `${gameUrl}`,
        //     '',
        //     `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
        //   )
        // }
        setGameLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'GAME-1001':
            onChangeAlertPopupMessageHandler('이용이 불가능합니다. 관리자에게 문의하세요.')
            break
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후에 다시 시도해주세요.')
            break
          case 'MEMBER-1019':
            alert(`${message}`)
            break
          default:
            onChangeAlertPopupMessageHandler('잠시후에 다시 시도해주세요.')
        }
      })
      .finally(() => {
        dispatch(fetchMemberInfoAction())
        setGameLoading(false)
      })
  }

  return (
    <>
      {casinoGameViewPopupShow && (
        <MobileCasinoGameViewPopup
          casinoGameUrl={casinoGameUrl}
          onCloseCasinoGameViewPopupShowHandler={onCloseCasinoGameViewPopupShowHandler}
        />
      )}
      {(loading || gameLoading) && <CustomLoading info={gameLoading ? '게임 접속 중입니다.' : ''} />}

      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}

      <motion.div
        initial={{ opacity: 0, scale: 0.3 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0.5, scale: 0.1 }}
        transition={{ duration: 0.6 }}
      >
        <MobileTopWrap>
          {/* <LiveCasinoHeaderWrap>
            <LiveCasinoHeaderInfo>라이브 카지노</LiveCasinoHeaderInfo>
          </LiveCasinoHeaderWrap> */}
          <MobileAssetWrap>
            <div style={{ color: `${HermesMainText}` }}>※ 카지노 입장시 자동으로 카지노캐쉬로 전환되십니다.</div>
            <div style={{ color: `${HermesMainText}` }}>
              ※ 카지노 이용종료시 "회수"버튼 누르시면 스포츠캐쉬로 이동됩니다.
            </div>
          </MobileAssetWrap>
          <MoneyExchangeContentWrap>
            <ContentWrap>
              <ContentRowWrap>
                <ContentRowSmall>
                  <div>
                    <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
                    <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                      {holdingMoney?.toString().replace(commonReg2, ',')} 원
                    </span>
                  </div>
                </ContentRowSmall>
                <ContentRowSmall>
                  <div>
                    <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
                    <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                      {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                    </span>
                  </div>
                </ContentRowSmall>
              </ContentRowWrap>
            </ContentWrap>

            {/* <Form
              onSubmit={onSubmit}
              initialValues={{
                moneyExchangeAmount,
                // 지갑머니
                holdingMoney,
                // 카지노 머니
                totalGameMoney,
              }}
            >
              {({ handleSubmit, form: { reset } }) => (
                <MoneyExchangeFormWrap horizontal onSubmit={handleSubmit}>
                  <MoneyExchangeFormGroup>
                    <MoneyExchangeFormField>
                      <Field name="toGameMoney">
                        {({ input, meta }) => (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <MoneyExchangeFormInputWrap>
                              <MoneyExchangeFormInput
                                {...input}
                                type="text"
                                value={moneyExchangeAmount}
                                onChange={e => {
                                  const { value } = e.target
                                  const money = value.replace(/[^0-9]/g, '').replace(commonReg2, ',')

                                  onClickMoneyExchangeAmount(money)
                                }}
                                placeholder="0"
                              />
                              <MoneyExchangeFormGroupIcon>원</MoneyExchangeFormGroupIcon>
                            </MoneyExchangeFormInputWrap>
                          </div>
                        )}
                      </Field>
                    </MoneyExchangeFormField>
                  </MoneyExchangeFormGroup>
                  <MoneyExchangeBtnBox>
                    <CustomDeleteButton type="button" onClick={onSubmitToWallet}>
                      <MoneyExchangeButtonInfo>스포츠머니로 이동</MoneyExchangeButtonInfo>
                    </CustomDeleteButton>
                    <CustomDeleteButton type="button" onClick={onSubmitToGame}>
                      <MoneyExchangeButtonInfo>카지노머니로 이동</MoneyExchangeButtonInfo>
                    </CustomDeleteButton>
                  </MoneyExchangeBtnBox>
                </MoneyExchangeFormWrap>
              )}
            </Form> */}
          </MoneyExchangeContentWrap>

          <LiveCasinoGameWrap>
            {liveCasinoVendors.map(item => {
              return (
                <LiveCasinoGameImgWrap key={item.vendorKey} onClick={() => gameStartHandler(item)}>
                  <LiveCasinoGameImg
                    src={
                      item.vendorImg ||
                      `${process.env.PUBLIC_URL}/img/game/${item.vendorName?.toString()?.replace(/(\s*)/g, '')}.png`
                    }
                  />
                  {item.vendorIsInspection && (
                    <CasinoInspectionBannerWrap>
                      <CustomBiLock />
                      <span>점검중</span>
                    </CasinoInspectionBannerWrap>
                  )}
                  {item.vendorNameKo === '마이크로게이밍 플러스 라이브' ? (
                    <>
                      <CasinoTitleWrap>
                        마이크로게이밍 PLUS <FaArrowRight />
                      </CasinoTitleWrap>
                    </>
                  ) : (
                    <>
                      <CasinoTitleWrap>
                        {item.vendorNameKo} <FaArrowRight />
                      </CasinoTitleWrap>
                    </>
                  )}
                </LiveCasinoGameImgWrap>
              )
            })}
          </LiveCasinoGameWrap>
        </MobileTopWrap>
      </motion.div>
    </>
  )
}

export default MobileLiveCasino

const MobileAssetWrap = styled.div`
  width: 100%;

  height: 35px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: rgb(29, 30, 33);
  padding: 3px 10px;
`

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    color: ${HermesMainText};
    span {
      color: ${HermesMainText};
    }
  }
  span {
    height: 100%;
    margin-left: -1px;
    font-size: 13px;
    font-weight: 500;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainText} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 15px !important;
  height: auto;
  cursor: pointer;
  color: ${HermesMainText} !important;
`

const CasinoTitleWrap = styled.div`
  width: 100%;
  background: #1c1f21;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: left;
  padding-left: 5px;
  height: 30px;
  text-algin: left;
  svg {
    position: absolute;
    right: 8px;
    width: 15px;
    height: 12px;
  }
`

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  font-size: 13px;
  padding: 0px 12px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

const LiveCasinoWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
`

const LiveCasinoHeaderWrap = styled.div`
  padding: 15px 10px;

  display: flex;
  align-items: center;
  border-bottom: solid 2px #252525;
`

const LiveCasinoHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;

  /* 엘로우폰트 */

  color: #b2b2b2;
`

const LiveCasinoGameWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 10px 0px;
  gap: 10px;
`

// hover 했을 때
const LiveCasinoGameHoverWrap = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  transition: all 1s;

  background: rgba(0, 0, 0, 0.57);

  display: none;
`

const LiveCasinoGameHoverIcon = styled.div`
  width: 64px;
  height: 64px;

  position: absolute;
  left: calc((160px - 64px) / 2);
  top: 25px;

  background: url(${process.env.PUBLIC_URL}/img/game/game-play-icon.png) no-repeat center;
`

// 버튼 안에 텍스트
const LiveCasinoGameHoverButtonInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #000000;
`

const LiveCasinoGameHoverButton = styled(ButtonH40)`
  width: 100px;
  height: 35px;
  border-radius: 0;

  position: absolute;
  left: calc((160px - 100px) / 2);
  top: 115px;

  // 호버
  &:hover {
    ${LiveCasinoGameHoverButtonInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${LiveCasinoGameHoverButtonInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${LiveCasinoGameHoverButtonInfo} {
      color: #ffffff4d;
    }
  }
`

const LiveCasinoGameImg = styled.img`
  width: auto;
  height: 115px;

  transition: all 0.2s linear;

  // background: ${props => props.url && `url(${props.url})`} no-repeat center;
`

const LiveCasinoGameImgWrap = styled.div`
  // width: 160px;
  width: auto;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
`

// 아래부터 머니이동
// content Wrap
const MoneyExchangeContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0px;
  border-bottom: 2px solid #252525;
`

// 지갑 머니 / 게임 머니 Wrap
const MoneyExchangeHeaderMoneyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  padding: 5px 0;

  padding-left: 10px;

  gap: 5px;
`

// 게임 머니 아이콘
const MoneyExchangeGameMoneyIcon = styled(GiMoneyStack)`
  color: ${colorMoneySvg};
  width: 30px;
  height: 30px;
`

//
const MoneyExchangeHeaderMoneyInfoWrap = styled.div`
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;

  gap: 10px;

  color: ${colorMoneyInfo};
`

const MoneyExchangeHeaderMoneyInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
`

const MoneyExchangeFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`

// content  header
const MoneyExchangeContentHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #edae07;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  padding: 10px 10px;

  color: ${colorMainText};
`
const MoneyExchangeFormGroup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 4px;
`

// sign up form field
const MoneyExchangeFormField = styled.div`
  // width: 270px;
  width: 80%;
  display: flex;
  flex-direction: column;
`

// sign up form input wrap
const MoneyExchangeFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  height: 35px;

  display: flex;
`

// sign up form input
const MoneyExchangeFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  height: 100%;

  font-style: normal;
  font-weight: 600;
  line-height: 35px;

  color: white;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    &::placeholder {
      color: black;
    }
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// MoneyExchangeFormGroupIcon
const MoneyExchangeFormGroupIcon = styled.div`
  padding: 6px;
  min-width: max-content;
  height: 100%;
  background: #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

// 이동하기 버튼 텍스트
const MoneyExchangeButtonInfo = styled.span`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #fff;
`

// 이동하기 버튼
const MoneyExchangeButton = styled(ButtonH40)`
  // width: 110px;
  height: 33px;
  border-radius: 0;

  // 비활성화
  &:disabled {
    height: 38px;
    ${MoneyExchangeButtonInfo} {
      color: #ffffff4d;
    }
  }
`

export const CustomIoWalletOutline = styled(IoWalletOutline)`
  color: red;
  width: 22px;
  height: 22px;
  margin: 0 8px 3px 0;
  color: ${colorWalletSvg};
`

export const CustomFaArrowRightArrowLeft = styled(FaArrowRightArrowLeft)`
  width: 30px !important;
  height: auto !important;
  color: red;
`

export const SelectedGameButtom = styled.button`
  width: 250px;
  // min-width: max-content;
  padding: 6px;
  height: 32px;
  font-style: normal;
  font-size: 10px;
  font-weight: 600;
  color: black;
  background: #f9e79f;
  border: 1px solid #f2f4f7;
  border-radius: 70%;
`

// 버튼 안에 텍스트
export const MoneyExchangeFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  text-align: center;

  color: white !important;
`

const MoneyExchangeBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 4px;
`

const CustomBiLock = styled(BiLock)`
  position: absolute;
  flex-direction: column;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 45px;
  height: 45px;
`

const CasinoInspectionBannerWrap = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  color: ${HermesMainText};
  background: rgba(17, 19, 20, 0.65);
  box-sizing: border-box;
  display: block;
  z-index: 100;

  span {
    position: absolute;
    flex-direction: column;
    margin-top: 15px;
    top: 40%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -40%);
  }
`
