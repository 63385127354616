import React, { useEffect, useState } from 'react'
import { FaSearchPlus } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { searchGame, searchGameUrl } from '../../api/game/gameApi'
import CustomAlert from '../../containers/CustomAlert'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { ButtonH40 } from '../../shared/components/GoldButton'
import Portal from '../../shared/components/Portal'
import { convertLongData } from '../../utils/editor'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import MobileCasinoGameViewPopup from './MobileCasinoGameViewPopup'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */

const MobileSlotCasinoGameModal = ({ onClose, visible, vendorName, agencyName, vendorType }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [])

  const [games, setGames] = useState([])

  const [loading, setLoading] = useState(true)
  const [gameLoading, setGameLoading] = useState(false)

  const [searchGameName, setSearchGameName] = useState('')
  const [searchBoolean, setSearchBoolean] = useState(false)

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  useEffect(() => {
    setLoading(true)
    searchGame({
      vendorName,
      agencyName,
      gameName: searchGameName,
    })
      .then(res => {
        setGames(res.data.games)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [vendorName, agencyName, searchBoolean])

  const [casinoGameViewPopupShow, setCasinoGameViewPopupShow] = useState(false)
  const onCloseCasinoGameViewPopupShowHandler = () => {
    setCasinoGameViewPopupShow(false)
  }

  const [casinoGameUrl, setCasinoGameUrl] = useState('')

  const notGamePopupVendors = ['ASIA GAMING SLOT']

  const gameStartHandler = game => {
    if (gameLoading) return

    setGameLoading(true)

    searchGameUrl({
      agencyName,
      vendorType,
      gameKey: game.gameKey,
    })
      .then(res => {
        const { gameUrl } = res.data

        if (!gameUrl) {
          // 없어진 게임일때 빈문자열로 내려옴
          onChangeAlertPopupMessageHandler('이용이 불가능합니다. 관리자에게 문의하세요.')
          return
        }

        window.open(
          `${gameUrl}`,
          '',
          `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
        )

        // const idx = notGamePopupVendors.findIndex(item => item === vendorName)

        // if (idx === -1) {
        //   setCasinoGameUrl(gameUrl)
        //   setCasinoGameViewPopupShow(true)
        // } else {
        //   window.open(
        //     `${gameUrl}`,
        //     '',
        //     `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
        //   )
        // }
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'GAME-1001':
            onChangeAlertPopupMessageHandler('이용이 불가능합니다. 관리자에게 문의하세요.')
            break
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          case 'MEMBER-1019':
            alert(`${message}`)
            break
          default:
            onChangeAlertPopupMessageHandler('잠시후에 다시 시도해주세요.')
        }
      })
      .finally(() => {
        dispatch(fetchMemberInfoAction())
        setGameLoading(false)
      })
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  return (
    <>
      {casinoGameViewPopupShow && (
        <MobileCasinoGameViewPopup
          casinoGameUrl={casinoGameUrl}
          onCloseCasinoGameViewPopupShowHandler={onCloseCasinoGameViewPopupShowHandler}
        />
      )}

      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}

      <Portal elementId="game-modal">
        <SlotCasinoGameModalOverlay visible={visible} />
        <SlotCasinoGameModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
          <SlotCasinoGameModalInner tabIndex="0">
            <SlotCasinoGameModalHeader>
              <SlotCasinoGameModalHeaderVendorLogDiv>{vendorName}</SlotCasinoGameModalHeaderVendorLogDiv>
              <SlotCasinoGameModalCloseButton
                onClick={close}
                src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                alt=""
              />
            </SlotCasinoGameModalHeader>

            <SlotCasinoGameModalContentWrap>
              <SlotCasinoGameModalHeaderVendorLogoWrap>
                {/* <SlotCasinoGameModalHeaderVendorLogDiv>{vendorName}</SlotCasinoGameModalHeaderVendorLogDiv> */}
                {/* <SlotCasinoGameModalHeaderVendorLogo
                src={`${process.env.PUBLIC_URL}/img/game/${vendorName?.toString()?.replace(/(\s*)/g, '')}-header.png`}
              /> */}
              </SlotCasinoGameModalHeaderVendorLogoWrap>
              <SportsGameSearchWrap>
                {/* 게임명 검색: */}
                <input
                  type="text"
                  name="searchGameName"
                  value={searchGameName}
                  placeholder="게임명을 검색하세요"
                  onChange={e => {
                    setSearchGameName(e.target.value)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setSearchBoolean(prev => !prev)
                    }
                  }}
                />
                <FaSearchPlus
                  onClick={() => {
                    setSearchBoolean(prev => !prev)
                  }}
                />
              </SportsGameSearchWrap>

              {loading || gameLoading ? (
                <CustomLoading info={gameLoading ? '게임 접속 중입니다.' : ''} />
              ) : (
                <SlotCasinoGameModalGameWrap>
                  {games.map(item => {
                    return (
                      <SlotCasinoGameImgWrap key={item.gameKey} onClick={() => gameStartHandler(item)}>
                        <SlotCasinoGameImg src={item.gameImg} />
                        <SlotCasinoGameTitleWrap>
                          <SlotCasinoGameTitle>{convertLongData(item.gameTitleKo)}</SlotCasinoGameTitle>
                        </SlotCasinoGameTitleWrap>
                        <SlotCasinoGameHoverWrap>
                          <SlotCasinoGameHoverIcon />
                          <SlotCasinoGameHoverButton>
                            <SlotCasinoGameHoverButtonInfo>시작하기</SlotCasinoGameHoverButtonInfo>
                          </SlotCasinoGameHoverButton>
                        </SlotCasinoGameHoverWrap>
                      </SlotCasinoGameImgWrap>
                    )
                  })}
                </SlotCasinoGameModalGameWrap>
              )}
            </SlotCasinoGameModalContentWrap>
          </SlotCasinoGameModalInner>
        </SlotCasinoGameModalWrapper>
      </Portal>
    </>
  )
}

export default MobileSlotCasinoGameModal

const SportsGameSearchWrap = styled.div`
  width: 100%;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 4px 8px;
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  input {
    background: rgb(29, 29, 29);
    height: 25px;
    border: none;
    color: ${HermesMainText};
    font-weight: 500;
    font-size: 12px;
    padding-left: 5px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 1px ${HermesMainBorder};
    }
  }
  svg {
    width: 22px;
    height: 20px;
    color: ${HermesMainText};
    &:hover {
      cursor: pointer;
    }
  }
`

const SlotCasinoGameModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: -50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  outline: 0;
`
const SlotCasinoGameModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const SlotCasinoGameModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: rgb(22, 23, 25);

  width: 100%;
  top: 120px;
  margin: 0 auto;
  overflow: auto;

  // border: 1px solid ${HermesMainBorder};

  height: calc(100% - 130px);
  //   height: 100%;
`

const SlotCasinoGameModalHeader = styled.div`
  height: 60px;
  // border-bottom: 1px solid ${HermesMainBorder};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

// 로고
const SlotCasinoGameModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

// 닫기 아이콘
const SlotCasinoGameModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`
// 게임사 (벤더) 로고
const SlotCasinoGameModalHeaderVendorLogoWrap = styled.div`
  width: 100%;
  height: 30px;

  position: relative;
`

const SlotCasinoGameModalHeaderVendorLogo = styled.img`
  width: 100%;
  height: 100%;
`
const SlotCasinoGameModalHeaderVendorLogDiv = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  background: linear-gradient(to right, #d6b218, #ffe800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

// modal body content
const SlotCasinoGameModalContentWrap = styled.div`
  padding: 0px 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 80px);
`

const SlotCasinoGameModalGameWrap = styled.div`
  width: 100%;
  // height: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  gap: 4px;

  overflow-y: scroll;
`

// 슬롯 게임 이미지 - 145 x 140
const SlotCasinoGameImg = styled.img`
  width: 165px;
  height: 140px;

  transition: all 0.2s linear;
`

// 슬롯 게임 타이틀 wrap
const SlotCasinoGameTitleWrap = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #1b1a18;
  border-top: 2px solid #4a4a4a;
`

// 슬롯 게임 타이틀
const SlotCasinoGameTitle = styled.span`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  line-height: 20px;

  text-align: center;
  color: #ffffff;
`

// hover 했을 때
const SlotCasinoGameHoverWrap = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  transition: all 1s;

  background: rgba(0, 0, 0, 0.8);

  display: none;
`

const SlotCasinoGameHoverIcon = styled.div`
  width: 64px;
  height: 64px;

  position: absolute;
  left: calc((100% - 64px) / 2);
  top: 20px;

  background: url(${process.env.PUBLIC_URL}/img/game/game-play-icon.png) no-repeat center;
`

// 버튼 안에 텍스트
const SlotCasinoGameHoverButtonInfo = styled.span`
  width: 39px;
  height: 23px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #000000;
`

const SlotCasinoGameHoverButton = styled(ButtonH40)`
  width: 100px;
  height: 30px;
  border-radius: 0;

  position: absolute;
  left: calc((100% - 100px) / 2);
  top: 115px;

  // 호버
  &:hover {
    ${SlotCasinoGameHoverButtonInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${SlotCasinoGameHoverButtonInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${SlotCasinoGameHoverButtonInfo} {
      color: #ffffff4d;
    }
  }
`

// 슬롯 게임 이미지 wrap
const SlotCasinoGameImgWrap = styled.div`
  // width: 145px;
  width: auto;
  // height: 170px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #f79c1b, #fddb2a);
  border-image-slice: 1;
  // 스케일 시 전체 크기는 고정하기 위함
  overflow: hidden;
`
